import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: () => import('../views/Accueil.vue')
  },
  {
    path: '/decompte',
    name: 'Decompte',
    component: () => import('../views/Decompte.vue')
  },
  {
    path: '/resultat',
    name: 'Resultat',
    component: () => import('../views/Resultat.vue')
  },
  {
    path: '/perdu',
    name: 'Perdu',
    component: () => import('../views/Perdu.vue')
  },
  {
    path: '/stats',
    name: 'Stats',
    component: () => import('../views/Stats.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
