import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    total: 0,
    version: 0,
    loaded: false,
    lots: [],
    gains: [],
  },
  mutations: {
    setLots(state, lots) {
      state.loaded = true;
      state.lots = lots
    },
    setTotal(state, total) {
      state.total = total
    },
    setGains(state, gains) {
      state.gains = gains
    },
    resetGains(state) {
      state.gains = [];
    },
    addGain(state, gain) {
      state.gains.push(gain);
    },
    setVersion(state, version) {
      state.version = version;
    },
    removeLot(state, index) {
      state.lots.splice(index, 1)
      console.log(index, state.lots.length)
    }
  },
  actions: {
    tirerLot({ state, commit }) {
      let index = Math.floor(Math.random() * state.lots.length)
      let lot = state.lots[index];
      console.log(lot)
      commit('addGain', lot);
      commit('removeLot', index);
      api.post('gains/', { gains: state.gains });

      return lot;
    },
    chargerLots({ commit }, options = {}) {
      console.log('chargerLots')
      api.get('setup/?' + Math.random()).then(response => {
        let version = response.data.version;
        if (version > this.state.version) {
          console.log('chargerLots version modifiée ' + version)

          api.get('lots/').then(response => {
            let lots = [];
            response.data.lots.forEach((lot) => {
              for (let i = 0; i < lot.quantite; i++) {
                lots.push(lot);
              }
            })
            commit('setGains', response.data.gains);
            commit('setVersion', version);
            commit('setTotal', response.data.total);
            commit('setLots', lots);
            if ('callback' in options) {
              options.callback();
            }
          })
        }
      })
    }
  },
  modules: {
  }
})
