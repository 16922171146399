<template>
  <div>
    <div id="app" v-if="$store.state.lots.length">
      <router-view />
    </div>
    <div
      v-if="$route.query.debug"
      class="stats"
    >{{$store.state.lots.length}} sur {{$store.state.total}}</div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.$store.dispatch("chargerLots");

    ion.sound({
      sounds: [
        {
          name: "bip",
        },
        {
          name: "cheer",
        },
        {
          name: "buzz",
        },
        {
          name: "tada",
        },
        {
          name: "tada",
        },
      ],
      volume: 0.5,
      path: "sons/",
      preload: true,
    });

  },
};
</script>

<style lang="scss">
.stats {
  position: absolute;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  color: white;
  font-size: 12px;
  padding: 5px;
  opacity: 0.5;
}
html,
body {
  height: 100vh;
}
body {
  background: url(assets/fond.png) no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
  #app {
    height: 100vh;
    & > * {
      width: 100%;
      height: 100%;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.warp-enter-active,
.warp-leave-active {
  transition: transform 1s;
}
.warp-enter {
  transform: scale(0) rotate(-720deg);
}

.warp-leave-to {
  transform: scale(1) rotate(0);
}
</style>
